export const theme = {
  breakpoints: {
    sm: "500px",
    md: "960px",
    lg: "1160px"
  }
};

export const color = {
  baseGrey: "#efeff7",
  baseWhite: "#fff",
  baseBlue: "#5c6bc0",
  grey900: "#23263b",
  grey800: "#36395a",
  grey700: "#484c7a",
  grey600: "#5a5e9a",
  grey500: "#777aaf",
  grey400: "#9698c3",
  grey300: "#b6b7d5",
  grey200: "#d6d6e7",
  grey100: "#f5f5fa",
  grey050: "#fcfcfd",
  grey000: "#fff",
  pink900: "#59063d",
  pink800: "#88085c",
  pink700: "#b80979",
  pink600: "#e90a96",
  pink500: "#f82caa",
  pink400: "#fb5abc",
  pink300: "#fd89ce",
  pink200: "#feb9e2",
  pink100: "#ffeaf6",
  nebula900: "#141d61",
  nebula800: "#1e2b8f",
  nebula700: "#2b3cbb",
  nebula600: "#3c4fe0",
  nebula500: "#5468ff",
  nebula400: "#7c8aff",
  nebula300: "#a3acff",
  nebula200: "#cacfff",
  nebula100: "#f2f3ff",
  cyan900: "#00526c",
  cyan800: "#00769b",
  cyan700: "#009bcb",
  cyan600: "#0db7eb",
  cyan500: "#2cc8f7",
  cyan400: "#5adaff",
  cyan300: "#89e5ff",
  cyan200: "#b9efff",
  cyan100: "#e8faff",
  green900: "#005e36",
  green800: "#028950",
  green700: "#06b66c",
  green600: "#0de589",
  green500: "#5feb9e",
  green400: "#88f0b3",
  green300: "#aaf4c8",
  green200: "#c9f8de",
  green100: "#e6fcf3",
  orange900: "#963209",
  orange800: "#bf470a",
  orange700: "#e8600a",
  orange600: "#f78125",
  orange500: "#faa04b",
  orange400: "#fcbc73",
  orange300: "#fed59a",
  orange200: "#ffe9c3",
  orange100: "#fff9ec",
  red900: "#83111e",
  red800: "#ab1325",
  red700: "#d4142a",
  red600: "#ee243c",
  red500: "#f4495d",
  red400: "#f86e7e",
  red300: "#fc95a1",
  red200: "#febdc5",
  red100: "#ffe6e9"
};
